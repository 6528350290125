#work {
  height: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include small-breakpoint {
    margin: 0 auto;
    padding: 0;
  }
  @include large-breakpoint {
    font-size: $lg-font;
  }
  .work-wrapper {
    max-width: 960px;
    margin: 0 auto;
  }
  h2 {
    font-size: 2em;
    text-align: center;
  }
  .work-container {
    margin: 0 auto;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .work-grid {
      @include large-breakpoint {
        width: $lg-container;
        margin: 0 50%;
      }
      .project {
        padding-bottom: 1%;
        position: relative;
        top: 0;
        left: 0;
        transition: $transition;
        &:hover {
          transform: scale(1.0105);
          cursor: pointer;
          opacity: 0.85;
        }
        img {
          width: 90%;
          display: block;
          margin: 0 auto;
          transition: $transition;
          @include large-breakpoint {
            width: 95%;
          }
          &:hover {
            box-shadow: $box-shadow;
          }
        }
        .responsive-image {
            max-width: 97%;
        }
      }
    }
  }
}

// Special Media Queries for Work Projects
@media screen and (min-width: 400px) {
  .work-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
  }
  .project {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 550px) {
  .work-grid {
    width: 100%;
    .project {
      width: calc(100% / 2);
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 800px) {
  .work-grid {
    width: 100%;
    .project {
      width: calc(100% / 3);
    }
  }
}
//
.middle {
  transition: .5s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.project:hover .middle {
  opacity: 0.9;
  cursor: pointer;
}

.project-text {
background-color: $accent;
color: white;
font-size: .8em;
padding: 10px 20px;
border-radius:  20px;
}
