.welcome {
  background: lighten(gray, 46%);
  width: 100vw;
  height: 50%;
  position: absolute;
  z-index: -1;
  -webkit-transform: skew(0deg, -7deg);
  transform: skew(0deg, -7deg);
  padding: 26vh 0;
  margin-top: -150px;
  overflow: hidden;
  .welcome-content {
    padding-top: 130px;
    margin: 0 auto;
    width: 70%;
    -webkit-transform: skew(0deg, 7deg);
    transform: skew(0deg, 7deg);
  }
  h1 {
    font-size: 3.6em;
    @include xs-breakpoint {
      font-size: 2.5em;
    }
    @include large-breakpoint {
      font-size: 5em;
    }
  }
}
canvas {
  transform: skew(0deg, 7deg);
  top: 0;
  left: 0;
  opacity: .5;
  position: absolute;
}

.more-button {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .fa {
    font-size: 2em;
    color: lighten(gray, 20%);
    animation: hover 750ms infinite alternate ease-in-out;
  }
}

  .footer-icons {
    display: flex;
    ul {
      width: 200px;
      list-style-type: none;
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      margin-right: 2.5%;
      padding: 10px;
      @include xs-breakpoint {
        flex-direction: column;
        align-items: flex-end;
        margin-right: 5%;
        }
      li {
        margin: 0;
        font-size: 1.7em;
        line-height: 1.2em;
      }
      &:last-child {
        padding-right: 0;
      }
      a, li {
        color: $accent;
        &:hover {
          animation: spinmerightround 550ms 1 forwards ease-in-out;
          opacity: 0.8;
        }
      }
    }
  }
