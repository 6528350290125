.next-prev-wrapper {
  margin: 25px auto 0 auto;
  display: flex;
  justify-content: flex-end;
  max-width: 960px;
  height: 100%;
  @include large-breakpoint {
    max-width: $lg-container;
    font-size: $lg-font;
  }
  @include small-breakpoint {
    justify-content: center;
  }
  a {
    font-weight: bold;
    color: $accent;
    transition: $transition;
    &:hover {
      color: $blue;
    }
  }
  .next-prev {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin-right: 50px;
    font-size: 0.8em;
    span {
      color: $blue;
    }
    @include small-breakpoint {
      margin-right: 0;
    }
    @include large-breakpoint {
      font-size: .675em;
    }
  }
}

#project {
  height: 100%;
  max-width: 960px;
  margin: 25px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include small-breakpoint {
    margin: 20px auto;
  }
  @include large-breakpoint {
    max-width: $lg-container;
    font-size: $lg-font;
  }
  .project-wrapper {
    h2, h3 {
      margin-top: 0;
    }
    h2, h4 {
      margin-bottom: 0;
    }
    h3 {
      font-size: 1.25em;
    }
    h4 {
      font-size: 1.2em;
    }
    h5 {
      font-size: 17px;
      margin-bottom: 0;
    }
    a {
      color: $accent;
      transition: $transition;
      font-weight: bold;
      &:hover {
        color: $blue;
      }
    }
    @include grid-column(12);
    .project-left {
      margin-bottom: 50px;
      @include grid-column (9);
      h2, h3 {
        text-align: center;
      }
      h2 {
        font-size: 2.5em;
        color: $accent;
      }
      .main-img {
        width: 100%;
      }
      .secondary-img {
        width: 90%;
        margin: 0 5%;
      }
      .main-img, .secondary-img {
        transition: $transition;
        &:hover {
          box-shadow: $box-shadow;
        }
      }
      .project-links {
        width: 260px;
        display: flex;
        justify-content: center;
        margin: 20px auto 0 auto;

      }
      .highlight {
        font-weight: bold;
        font-size: 18px;
      }

      .fa-check-circle {
        color: green;
      }
      @include small-breakpoint {
        @include grid-column(12);
      }
    }
    .project-right {
      @include grid-column (3);
      @include small-breakpoint {
        @include grid-column (12);
      }
      h3 {
        margin-bottom: -5px;
        }
      ul {
        li {
          color: #666;
          font-size: 0.85em;
          line-height: 1.4em;
          a {
            font-weight: bold;
            color: $accent;
            transition: $transition;
            &:hover {
              color: $blue;
            }
          } // a
        } // li
      } // ul
      .project-col {
      @include small-breakpoint {
        @include grid-column (4);
        }
      @include xs-breakpoint {
        @include grid-column (12);
        margin: 0;
        }
      } // Project-col
    } // Project right
  }
}
