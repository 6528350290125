.content-wrapper {
  margin: 15px;
  padding: 20px;
  border-radius: 3px;
  background-color: lighten($accent, 30%);
  box-shadow: 10px 10px $headline;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-around;
    .search-input {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      border: 1px solid #292929;
      text-align: center;
      padding: 5px 10px;
      border-radius: 3px;
    }
    label {
      margin-top: 5%;
      color: $accent;
      background-color: $headline;
      box-shadow: 4px 5px $accent;
      border-radius: 4px;
      padding: 2%;
      font-family: $primary;
      font-size: 2em;
      width: 70%;
      margin-right: auto;
      margin-left: auto;
    }
    input[type=text] {
      color: $headline;
      border: 3px solid $accent;
      font-size: 18px;
      background-color: $white;
      &:focus {
        border: 3px solid $headline;
        border-bottom: $headline;
        box-shadow: none;
        background-color: lighten($accent, 25%);
        color: darken($headline, 10%);
      }
    }
    button {
      background: none;
      border: none;
      color: $headline;
      font-size: 40px;
      transition: all 0.4s ease-in-out;
      &:focus, &:active, &:hover {
        outline: none;
        transform: scale(1.2);
        color: $accent;
        cursor: pointer;
      }
    }
  }
}

input::placeholder {
  color: $black;
}

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]){
  border-bottom: 3px solid $headline;
  box-shadow: none;
}


.result {
  padding: 5%;
  li {
    line-height: 28px;
  }
  hr {
    border-top: 7px solid $headline;
	  border-bottom: 3px solid $accent;
  }
}
