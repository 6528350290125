#contact-me {
  background: #FEAC5E;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100%;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  color: $white;

  .contact-wrapper {
    @include grid-column(12);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 960px;
    margin: 0 auto;
    @include large-breakpoint {
      width: $lg-container;
      font-size: $lg-font;
    }
    .bottom-icons {
      width: 200px;
      ul {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          font-size: 25px;
          transition: $transition;
          a {
            color: $accent;
          }
          &:hover {
            opacity: 0.7;
            transform: translateY(-.1em);
          }
        }
      }
    }
    h2 {
      font-size: 2em;
      text-align: center;
    }
    p {
      text-align: center;
      font-size: 1.2em;
      width: 70%;
      max-width: 800px;
      margin: 0;
    }
    form {
      margin: 0 auto;
      width: 60%;
      @include small-breakpoint {
        width: 80%;
      }
      input, textarea {
        padding: 7px 5px;
        width: 95%;
        display: block;
        margin: 15px auto;
        border: 2px solid transparent;
        border-radius: $radius;
        outline: none;
        transition: $transition;
        &:focus {
          border: 2px solid $accent;
          opacity: 0.8;
        }
      }
      input {
        height: 25px;
      }
      input[type=text], input[type=email] {
        font-family: $primary;
        font-size: 0.8em;
        letter-spacing: $letter-spacing;
      }
      textarea {
        height: 100px;
        color: $black;
        font-family: $primary;
        font-size: 0.8em;
        letter-spacing: $letter-spacing;
      }
    }
  }
}

// Textarea placeholder color
textarea::-webkit-input-placeholder {
color: $black;
}

textarea:-moz-placeholder { /* Firefox 18- */
color: $black;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
color: $black;
}

textarea:-ms-input-placeholder {
color: $black;
}
