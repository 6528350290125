// Contact link in header
.contact-button {
  background-color: $white;
  padding: 10px 15px;
  border-radius: 20px;
  color: $black;
  transition: 0.3s all ease-in-out;
  &:hover {
    box-shadow: 0 2px 10px -1px darken(orange, 10%);
    background-color: lighten(orange, 45%);
  }
}

// Contact form button
.form-button {
  @extend .contact-button;
  font-family: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: center;
  border-radius: 25px;
  background-color: $accent;
  color: $white;
  font-weight: bold;
  padding: 15px 40px;
  margin: 0 auto;
  cursor: pointer;
  outline: none;
  transition: $transition;
  &:hover {
    background-color: darken($accent, 15%);
    box-shadow: 0 3px 10px -1px $accent;
    opacity: 0.9;
    color: $white;
  }
  &:active {
    transform: translateY(.1em);
    box-shadow: 0 3px 10px -1px $accent;
  }
}

// "View more work on GitHub" button in work section

.more-work-wrapper {
  padding: 20px;
  .more-work {
    @extend .form-button;
    margin: 0 auto;
    color: white;
    background-color: $accent;
    @include large-breakpoint {
      width: 225px;
      padding: 20px 25px;
    }
    &:hover {
      background-color: lighten($accent, 10%);
      box-shadow: 0 2px 10px -1px darken($accent, 10%);
    }
  }
}

// Project detail buttons

.project-links {
  a {
    @extend .form-button;
    padding: 10px;
    margin: 0 3px;
    width: 120px;
    color: $white !important;
    font-size: 0.9em;
    background-color: $accent;
    @include large-breakpoint {
      font-size: 0.8em;
      padding: 15px;
      width: 50%;
    }
  &:hover {
    background-color: lighten($accent, 10%);
    box-shadow: 0 2px 10px -1px darken($accent, 10%);
    }
  }
}
