// Colors
$white: #fafafa; // Whiteish
$black: #333; // Blackish
$headline: #424a6d; // Dark blue
$accent: #663399;
$orange: #FEAC5E;
$blue: #4BC0C8;
$pink: #d687b1;
$purple: #C779D0;
$gray: #ccc;

//Rose in header
$bud: #ea4944;
$stem: #326d32;
///////////////

// Fonts
$header: 'Merriweather', serif;
$primary: 'Muli', sans-serif;
$rose: 'Karla', sans-serif;
///////////////

// Cohesive styling
$transition: 0.3s all ease-in-out;
$radius: 10px;
$letter-spacing: .0075em;
$box-shadow: 1px 1px 12px 1px $gray;
///////////////

// Breakpoints
$xs-bp: "only screen and (max-width: 550px)";
$small-bp: "only screen and (max-width: 800px)";
$medium-bp: "only screen and (max-width: 958px)";
$large-bp: "only screen and (min-width: 1600px)";

// Styles for $large-bp

//Width of containers for $large-bp
$lg-container: 1300px;
$lg-font: 1.3em;
