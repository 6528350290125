  #about {
    height: 100%;
    max-width: 960px;
    padding: 100px 0;
    margin: 10vh auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include small-breakpoint {
      margin: 0 auto;
    }
    @include large-breakpoint {
      max-width: $lg-container;
      font-size: $lg-font;
    }
    h2 {
      font-size: 2.2em;
      margin-bottom: 0;
    }
    h4 {
      font-size: .925em;
    }
    a {
      color: $accent;
      transition: $transition;
      &:hover {
        color: $pink;
      }
    }
    @include small-breakpoint {
      margin: 0 auto;
    }
    .about-info {
      @include grid-column(12);
      max-height: 300px;
      @include small-breakpoint {
        h2 {
          font-size: 1.2em;
        }
        h4 {
          font-size: 0.9em;
        }
      }
      .about-info-right {
        @include grid-column(3);
        .image-wrapper {
          .photo {
            max-width: 100%;
          }
        }
        @include xs-breakpoint {
          @include grid-column(12);
          display: flex;
          justify-content: center;
          img {
            width: 200px;
            height: 200px;
          }
        }
      }
      .about-info-left {
        @include grid-column(9);
        @include xs-breakpoint {
          @include grid-column(12);
        }
      }

    }
    .col-wrapper {
      @include grid-column(12);
      display: flex;
      justify-content: space-between;
      @include medium-breakpoint {
      }
      @include xs-breakpoint {
        margin: 180px auto 0 auto;
        flex-direction: column;
      }
      .remove {
        @include xs-breakpoint {
          margin-top: 50px;
        }
      }
      .col {
        @include grid-column(4);
        strong {
          text-align: center;
          font-size: 1.1em;
          font-family: $header;
          .col-img {
            margin-top: 3%;
            margin-bottom: -5px;
            width: 30px;
            transition: $transition;
            &:hover {
              transform: scale(1.05);
            }
          }
        }
        ul {
          padding: 0;
          li {
            margin-bottom: 10px;
            list-style-type: none;
            padding: 0;
            font-size: 0.9em;
          }
        }
        @include xs-breakpoint {
          @include grid-column(12);
          margin-top: 10vh;
        }
        @include small-breakpoint {
          @include grid-column(12);
          margin-top: 7vh;
        }
      }
    }
  }
