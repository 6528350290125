nav {
  background: #FEAC5E;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 60px;
  position: relative;
  width: 100%;
  z-index: 1;
  -webkit-transition: .13s all;
  transition: .13s all;
  box-shadow: 1px 1px 1px 1px #ddd;
  }
  .left {
    font-size: 1.6em;
    -webkit-text-stroke-width: .5px;
    font-family: $rose;
    transform: skewX(-3deg);
    .bud {
      color: $bud;
    }
    .green {
      color: $stem;
    }
  }
  @include xs-breakpoint {
    .left {
      display: none;
    }
}

nav,
nav ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul {
  height: 100%;
  width: 95%;
}

nav ul li {
  list-style: none;
  margin-right: 20px;
  &:hover {
  }
}

nav li:first-child {
  margin-right: auto;
}

// Special media query for Home link on small screens

@media screen and (min-width: 550px) {
  .home {
    display: none;
  }
}

.mainNav {
  &:hover {
    color: $pink;
    background-color: red;
    opacity: .1;
  }
}
