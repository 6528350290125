// Global typography


body {
  font-family: $primary;
  letter-spacing: $letter-spacing;
  overflow-x: hidden;
  font-size: 17px;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 25px;
  @include large-breakpoint {
    line-height: 30px;
  }
}

a {
  text-decoration: none;
  color: $white;
}

li {
  font-family: $primary;
  line-height: 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $header;
  line-height: 1.3em;
  letter-spacing: .01em;
}

ul {
  list-style-type: none;
  padding: 0;
}

// Navigation
.nav-style
  {
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #333;
    @include nudge;
  }
  &:visited {
    color: $white;
  &:active {
    color: #333;
  }
 }
}
