// From hero.scss, canvas rotation
@keyframes fade {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.7;
  }
  100% {
    transform: rotate(360deg) scale(2);
    opacity: 1;
  }
}

// From hero.scss, down button (currently not in use)
@keyframes hover {
  from {
    transform: translateY(0.2em);
  }
  to {
    color: translateY(-0.2em);
  }
}

// From hero.scss, spin effect on social media icons
@keyframes spinmerightround {
  0% {
    color: $pink;
    transform: rotate(0deg);
  }
  100% {
    color: $accent;
    transform: rotate(720deg);
  }
}
